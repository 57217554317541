import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import { Button } from '@mui/material';
import { usePrintHwasung } from 'apps/pos-main/src/modules/hwasung/usePrintHwasung';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';

export const HwasungPrintActions = ({
	selected,
	setSelected,
	orderWithDetail,
}: OrderAndPaymentsType & {
	orderWithDetail: OrderDetailType;
}) => {
	const print = usePrintHwasung();
	return (
		<Button
			onClick={() => print({ order: orderWithDetail })}
			disabled={orderWithDetail.status !== 'COMPLETED'}
			fullWidth
			variant={'outlined'}
			color={'primary'}>
			{'티켓 재인쇄'}
		</Button>
	);
};
