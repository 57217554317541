import { useMemo } from 'react';
import { useSessionGroupMapBySessionId } from './useSessionGroupMapBySessionId';
import { CartLineInfoType, CartLineProductInfoType } from '@kinderlabs-pos/shared-data-type';

export type RequiredField<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export const getSessionCartLines = (cartLines: CartLineInfoType[]) => {
	return (cartLines.filter((line) => line.type === 'PRODUCT') as CartLineProductInfoType[]).filter(
		(line) => line.sessionInfo
	) as RequiredField<CartLineProductInfoType, 'sessionInfo'>[];
};

export const getNoSessionCartLines = (cartLines: CartLineInfoType[]) => {
	return (cartLines.filter((line) => line.type === 'PRODUCT') as CartLineProductInfoType[]).filter(
		(line) => !line.sessionInfo
	);
};

export const useGetSessionCartLinesWithSessionInfo = ({ storeId }: { storeId?: number }) => {
	const sessionGroupMapBySessionId = useSessionGroupMapBySessionId(storeId);

	return (cartLines: CartLineInfoType[]) => {
		const sessionCartLines = getSessionCartLines(cartLines).map((line) => {
			return {
				...line,
				fullSessionInfo: sessionGroupMapBySessionId[line.sessionInfo.sessionId].sessionInfo,
				sessionGroupInfo: sessionGroupMapBySessionId[line.sessionInfo.sessionId].sessionGroupInfo,
				sessionBoardInfo:
					sessionGroupMapBySessionId[line.sessionInfo.sessionId].sessionInfo.sessionBoard[
						line.sessionInfo.sessionBoardIndex
					],
			};
		});

		return sessionCartLines.reduce((acc, line) => {
			const key = `${line.sessionInfo.sessionId}::${line.sessionInfo.sessionBoardIndex}`;

			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(line);
			return acc;
		}, {} as Record<string, typeof sessionCartLines>);
	};
};

export const useSessionCartLinesWithSessionInfo = ({
	storeId,
	cartLines,
}: {
	storeId?: number;
	cartLines: CartLineInfoType[];
}) => {
	const getSessionCartLinesWithSessionInfo = useGetSessionCartLinesWithSessionInfo({ storeId });

	return useMemo(() => {
		return getSessionCartLinesWithSessionInfo(cartLines);
	}, [cartLines]);
};
