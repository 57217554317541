import { useQuery } from '@tanstack/react-query';
import { SessionsQuery } from '@kinderlabs-pos/feature/domain-sessions';
import { useAtomValue } from 'jotai';
import { StoreInfoState } from '@kinderlabs-pos/state';
import Grid2 from '@mui/material/Unstable_Grid2';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogContent, Typography } from '@mui/material';
import { Suspense } from 'react';
import { SessionDialog } from './SessionDialog';

export interface IExchangeBoardProps {}
export const SessionBoard: React.FC<IExchangeBoardProps> = ({}) => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const sessions = useQuery(SessionsQuery.query.sessions.allForSale({ storeId }));

	return (
		<Grid2
			container
			spacing={2}>
			{(sessions.data ?? []).map((session, idx) => (
				<Grid2
					key={idx}
					xs={4}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<SessionDialog
								scroll='paper'
								session={session}
								open={open}
								closeDialog={closeDialog}>
								<Suspense>
									{/* <KitchenOrderDetailContents
											kitchenOrderInfo={kitchenOrderInfo}
											Actions={
												<Stack
													direction={'row'}
													spacing={1}>
													<Button
														sx={{ flex: 1 }}
														variant='outlined'
														disabled={['CANCELED', 'COMPLETED'].includes(kitchenOrderInfo.status)}
														onClick={() => {
															handleSwitchStatus('cancel');
														}}>
														{'조리 취소'}
													</Button>
												</Stack>
											}
										/> */}
								</Suspense>
							</SessionDialog>
						)}>
						{(openDialog) => (
							<Button
								size='small'
								variant='outlined'
								sx={{ height: 60, width: '100%' }}
								onClick={openDialog}>
								<Typography>{session.name}</Typography>
							</Button>
						)}
					</WithDialog>
				</Grid2>
			))}
		</Grid2>
	);
};
