import { MinusOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import {
	getSessionCartLines,
	SessionGroupTypeForSale,
	SessionsQuery,
	SessionTypeForSale,
} from '@kinderlabs-pos/feature/domain-sessions';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { AdminProductInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogProps,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useAtomValue } from 'jotai';
import { Suspense, useMemo, useState } from 'react';

export const SessionDialog = ({
	closeDialog,
	session,
	...others
}: DialogProps & {
	session: SessionGroupTypeForSale;
	closeDialog: () => void;
}) => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [selectedSession, setSelectedSession] = useState<SessionTypeForSale | undefined>(undefined);
	const lines = SessionsQuery.lines.useMap({ storeId });

	return (
		<WithDialog.XlDialog
			maxWidth={'md'}
			dialogTitle={session.name}
			closeDialog={closeDialog}
			{...others}>
			<Suspense>
				<DialogContent sx={{ width: 540, height: 360 }}>
					<Stack sx={{ height: '100%', width: '100%' }}>
						{!selectedSession &&
							session.sessionList.map((session) => {
								const occupying = lines[session.sessionId] ? lines[session.sessionId].length : 0;

								return (
									<Stack
										onClick={() => setSelectedSession(session)}
										width={'100%'}
										alignItems={'center'}
										justifyContent={'space-between'}
										direction={'row'}
										py={2}
										key={session.sessionId}>
										<Stack>
											<Typography variant='h4'>{session.name}</Typography>
											<Typography variant='subtitle1'>{`${session.startTime} ~ ${session.endTime}`}</Typography>
										</Stack>
										<Stack
											direction={'row'}
											alignItems={'center'}>
											<Stack sx={{ pr: 2 }}>
												<Typography>{`${session.capacity - occupying} / ${
													session.capacity
												}`}</Typography>
											</Stack>
											<Stack>
												<RightOutlined />
											</Stack>
										</Stack>
									</Stack>
								);
							})}
						{selectedSession && <SelectedSectionZone selectedSession={selectedSession} />}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						variant='outlined'
						onClick={closeDialog}>
						닫기
					</Button>
				</DialogActions>
			</Suspense>
		</WithDialog.XlDialog>
	);
};

const SelectedSectionZone = ({ selectedSession }: { selectedSession: SessionTypeForSale }) => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId });
	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const sessionCartLines = getSessionCartLines(cartLines).filter(
		(cl) => cl.sessionInfo.sessionId === selectedSession.sessionId
	);
	const orderReducer = OrderState.cart.useDispatcher();
	const quantityMap = useMemo<Record<number, { boardIndex: number; cartLineId: string }[]>>(() => {
		return {
			0: sessionCartLines
				.filter((cl) => cl.sessionInfo.sessionBoardIndex === 0)
				.map((cl) => ({ boardIndex: 0, cartLineId: cl.id })),
			1: sessionCartLines
				.filter((cl) => cl.sessionInfo.sessionBoardIndex === 1)
				.map((cl) => ({ boardIndex: 0, cartLineId: cl.id })),
			2: sessionCartLines
				.filter((cl) => cl.sessionInfo.sessionBoardIndex === 2)
				.map((cl) => ({ boardIndex: 0, cartLineId: cl.id })),
			3: sessionCartLines
				.filter((cl) => cl.sessionInfo.sessionBoardIndex === 3)
				.map((cl) => ({ boardIndex: 0, cartLineId: cl.id })),
		};
	}, [sessionCartLines]);

	const lines = SessionsQuery.lines.useMap({ storeId });
	const occupying = lines[selectedSession.sessionId] ? lines[selectedSession.sessionId].length : 0;

	const [quantityControlled, setquantityControlled] = useState(false);
	const occupySession = SessionsQuery.lines.useOccupy({});
	const handleClickOccupySession = async ({ boardIndex }: { boardIndex: 0 | 1 | 2 | 3 }) => {
		const productInfo = selectedSession.sessionBoard[boardIndex].productInfoId
			? productMap[selectedSession.sessionBoard[boardIndex].productInfoId as string]
			: undefined;

		if (!productInfo) return;

		const cartLineId = getUuidV4();
		const { seq } = await occupySession.mutateAsync({
			storeId,
			sessionId: selectedSession.sessionId,
			userId: deviceId.toString() ?? '',
			externalKey: cartLineId,
			capacity: selectedSession.capacity,
		});

		if (!seq && seq !== 0) return;

		orderReducer({
			type: 'SESSION_PRODUCT',
			subAction: {
				type: 'ADD',
				products: [
					{
						cartLineId,
						productInfo,
						sessionId: selectedSession.sessionId,
						sessionBoardIndex: boardIndex,
					},
				],
			},
		});
	};

	const resolveSession = SessionsQuery.lines.useResolveByExternalKey({});
	const handleClickResolveSession = async ({ boardIndex }: { boardIndex: 0 | 1 | 2 | 3 }) => {
		if (quantityMap[boardIndex].length === 0) return;

		const cartLine = quantityMap[boardIndex][quantityMap[boardIndex].length - 1];
		const cartLineId = cartLine.cartLineId;

		await resolveSession.mutateAsync({
			storeId,
			sessionId: selectedSession.sessionId,
			keyList: [cartLineId],
			includeOrdered: false,
		});

		orderReducer({
			type: 'BOARD',
			subAction: {
				type: 'DELETE',
				cartLineId,
			},
		});
	};

	return (
		<Stack height={'100%'}>
			<Stack
				width={'100%'}
				alignItems={'center'}
				justifyContent={'space-between'}
				direction={'row'}
				py={2}
				key={selectedSession.sessionId}>
				<Stack sx={{ pr: 2 }}>
					<Typography variant='h4'>{selectedSession.name}</Typography>
					<Typography variant='subtitle1'>{`${selectedSession.startTime} ~ ${selectedSession.endTime}`}</Typography>
				</Stack>
				<Stack
					direction={'row'}
					alignItems={'center'}>
					<Stack>
						<Typography>{`${selectedSession.capacity - occupying} / ${
							selectedSession.capacity
						}`}</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Grid2 container>
				{selectedSession.sessionBoard.map((board, boardIdx) => {
					const product = board.productInfoId ? productMap[board.productInfoId] : undefined;

					if (!product || board.disabled) return null;

					return (
						<Grid2
							xs={12}
							key={boardIdx}>
							<Stack
								direction={'row'}
								justifyContent={'space-between'}
								alignItems={'center'}
								sx={{ width: '100%', py: 1 }}>
								<Stack>
									<Typography>{product.name}</Typography>
									<Typography>{product.price.toLocaleString()}</Typography>
								</Stack>
								<Stack
									direction={'row'}
									alignItems={'center'}
									gap={2}>
									<IconButton
										disabled={quantityControlled}
										onClick={() => {
											setquantityControlled(true);
											handleClickResolveSession({ boardIndex: boardIdx as 0 | 1 | 2 | 3 });
											setTimeout(() => setquantityControlled(false), 300);
										}}>
										<MinusOutlined />
									</IconButton>
									<Typography>{quantityMap[boardIdx].length}</Typography>
									<IconButton
										disabled={occupying >= selectedSession.capacity || quantityControlled}
										onClick={() => {
											setquantityControlled(true);
											handleClickOccupySession({ boardIndex: boardIdx as 0 | 1 | 2 | 3 });
											setTimeout(() => setquantityControlled(false), 300);
										}}>
										<PlusOutlined />
									</IconButton>
								</Stack>
							</Stack>
						</Grid2>
					);
				})}
			</Grid2>
		</Stack>
	);
};
