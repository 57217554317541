import { ipcHwasungApiName } from '@kinderlabs-pos/ipc/common';
import { HAS_NO_ELECTRON, isOnElectron } from './const';
import { HwasungPrintRequestType } from '@kinderlabs-pos/shared-data-type';

const invoke티켓출력 = (
	args:
		| {
				type: 'checkStatus';
		  }
		| {
				type: 'printRequest';
				args: HwasungPrintRequestType;
		  }
) => {
	if (isOnElectron) window[ipcHwasungApiName].invoke(args);
};

export const ReactHwasungIPC = {
	invoke티켓출력,
};

// 해당 코드는 의존성에 영향을 줌.
// 브라우저 프로세스가 접근하지 못하는 영역에 접근하게됨. 주의필요.
// 해당 기능이 꼭 필요하다면 nx webpack 설정을 건드려 쓰자.
// const isSample = process.env['NODE_ENV'] === 'sample';
// if (isSample) {
// 	console.log('NODE_ENV가 SAMPLE이 되었음');
// 	ReactIPC_Pos_Hardware.invoke띠지출력 = hwPrintLabel;
// 	ReactIPC_Pos_Hardware.sendOpenCashBox = hwOpenDrawer;
// 	ReactIPC_Pos_Hardware.invoke고객영수증출력 = hwPrintCustomerReceipt;
// 	ReactIPC_Pos_Hardware.invoke교환권출력 = hwPrintExchangeCoupon;
// 	ReactIPC_Pos_Hardware.invoke시재점검표출력 = hwPrintVaultcashReceipt;
// 	ReactIPC_Pos_Hardware.invoke프론트영수증출력 = hwPrintFoodFrontReceipt;
// 	ReactIPC_Pos_Hardware.invoke주방영수증출력 = hwPrintFoodKitchenReceipt;
// 	ReactIPC_Pos_Hardware.invoke커스텀영수증출력 = hwPrintCustomReceipt;
// }
