import {
	getNoSessionCartLines,
	useGetSessionCartLinesWithSessionInfo,
} from '@kinderlabs-pos/feature/domain-sessions';
import { ReactHwasungIPC } from '@kinderlabs-pos/ipc/react';
import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import { AdminProductInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

export const usePrintHwasung = () => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const getSessionCartLinesWithSessionInfo = useGetSessionCartLinesWithSessionInfo({
		storeId: storeId,
	});
	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId });

	return async ({ order }: { order: OrderDetailType }) => {
		const noSessionLines = getNoSessionCartLines(order.cart.lines ?? []);
		const sessionGroupBy = getSessionCartLinesWithSessionInfo(order.cart.lines);
		const payment = order.payments[0];

		const 화성Request = {
			TYPE: '통합관',
			DATE: dayjs(order.created).format('YYYY-MM-dd'),
			// ORDER_NUM: order.id,
			ORDER_NUM: payment?.receipt?.approvalId ?? 'TEST1234',
			TOTAL: `${OrderState.cart.utils.getAggregate(order.cart).totalToPay.toLocaleString()}원`,
			TOTAL_PEOPLE: `${noSessionLines.reduce((acc, cl) => acc + cl.quantity, 0)}명`,
			ORDER_LIST: noSessionLines.map((cl) => {
				const product = productMap[Number(cl.productInfoId)];
				return {
					NAME: product.description ?? cl.name,
					QUANTITY: cl.quantity.toString() + '명',
					PRICE: cl.price.toLocaleString() + '원',
				};
			}),
			ACTIVITY_LIST: Object.values(sessionGroupBy).map((cartLines) => {
				const cl = cartLines[0];

				return {
					NAME: cl.fullSessionInfo.name,
					TIME: `${cl.fullSessionInfo.startTime} ~ ${cl.fullSessionInfo.endTime}`,
					BABY:
						cartLines
							.reduce(
								(acc, cl) => (acc + cl.sessionInfo.sessionBoardIndex === 0 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
					CHILD:
						cartLines
							.reduce(
								(acc, cl) => (acc + cl.sessionInfo.sessionBoardIndex === 1 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
					YOUTH:
						cartLines
							.reduce(
								(acc, cl) => (acc + cl.sessionInfo.sessionBoardIndex === 2 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
					ADULT:
						cartLines
							.reduce(
								(acc, cl) => (acc + cl.sessionInfo.sessionBoardIndex === 3 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
				};
			}),
		};

		await ReactHwasungIPC.invoke티켓출력({
			type: 'printRequest',
			args: 화성Request,
		});
	};
};
